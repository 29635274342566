import { gql } from './utils';

export const manufacturerInfoQuery = gql(`
  query manufacturerInfo($manufacturerName: String!) {
    manufacturerInfo(manufacturerName: $manufacturerName) {
      name
      companyName
      address
      country
      email
      phoneNumber
      homepageUrl
      linkToSecurityInfo
    }
  }
`);

export type ManufacturerInfo = {
  name: string;
  companyName: string;
  address: string;
  country: string;
  email?: string | null;
  phoneNumber?: string | null;
  homepageUrl?: string | null;
  linkToSecurityInfo?: string | null;
};
