import { useApi } from '~/composables';
import { manufacturerInfoQuery, ManufacturerInfo } from '~/graphql/getManufacturerInfo';
import { Logger } from '~/helpers/logger';

export function useManufacturerApi() {
  const { query } = useApi();

  const getManufacturerInfo = async (manufacturerName: string): Promise<ManufacturerInfo | null> => {
    const { data, errors } = await query<{ manufacturerInfo: ManufacturerInfo }>(manufacturerInfoQuery, { manufacturerName });
    if (!data?.manufacturerInfo) {
      Logger.error('Could not retrieve manufacturer info');
      return null;
    }

    if (errors?.length > 0) {
      Logger.error(errors);
      return null;
    }

    return data.manufacturerInfo;
  };

  return { getManufacturerInfo };
}
