


























import {
  defineComponent, ref, Ref, watch,
} from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { arrowRightIcon } from '~/cms/helpers/customIcons';
import { getMinimumRate, MINIMUM_RATE } from '~/components/Financing/financing';
import { useUiState } from '~/composables';
import { Routes } from '~/helpers/routes';

export default defineComponent({
  name: 'FinancingBlock',
  components: {
    SfIcon,
  },
  props: {
    productPrice: {
      type: Number,
      required: true,
    },
    productSpecialPrice: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { toggleFinancingModal } = useUiState();
    const financingPrice: Ref<number> = ref(0);
    const minimumRate: Ref<number> = ref(MINIMUM_RATE);

    // we need to watch for changes here as the price updates may be delayed
    watch(() => props, () => {
      const actualPrice = props.productSpecialPrice || props.productPrice || 0;
      financingPrice.value = actualPrice;
      minimumRate.value = getMinimumRate(actualPrice);
    }, { immediate: true, deep: true });

    const handleOpenFinancing = () => {
      toggleFinancingModal({ price: financingPrice.value });
    };

    return {
      handleOpenFinancing,
      arrowRightIcon,
      minimumRate,
      Routes,
    };
  },
});
