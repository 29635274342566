




























import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  Ref,
  useContext,
  watch,
} from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { ManufacturerInfo } from '~/graphql/getManufacturerInfo';
import { Routes } from '~/helpers/routes';
import { ProductCustomAttributes } from '~/modules/catalog/product/types';

enum EnumAccordeonItemIcon {
  PLUS = 'plus',
  MINUS = 'minus',
}

interface AccordeonItem {
  _uid: string,
  heading: string,
  content: string,
}

interface AccordeonItemState {
  index: number,
  isOpen: boolean
}

export default defineComponent({
  components: {
    SfIcon,
  },
  props: {
    attributes: { type: Object, default: () => {} },
    manufacturerInfo: { type: Object as PropType<ManufacturerInfo | null>, default: () => null },
    eans: { type: Array, default: () => [] },
  },
  setup(props) {
    const { app: { i18n }, $config } = useContext();
    const { storeUrl } = $config;
    const customAttributes: ComputedRef<ProductCustomAttributes> = computed(() => props.attributes);

    const rowTemplate = ((label: string, value: string, suffix?: string) => `
    <div class="product-accordeon__row py-2">
      <span class="text-zinc-500 text-xl font-semibold leading-[1.875rem]">${label}:</span>
      <span class="text-zinc-900 text-xl leading-[1.875rem]">${value} ${suffix || ''}</span>
    </div>`);

    function generateAttributeHtml(attributeName: string, attributeValue: string, attributeSuffix?: string) {
      return attributeValue
        ? rowTemplate(String(i18n.t(attributeName)), attributeValue, attributeSuffix)
        : '';
    }

    const keyDataAttributes = [
      { name: 'Model Year', value: customAttributes.value?.model_year },
      { name: 'Operation Data', value: customAttributes.value?.area_of_use },
      { name: 'Geometry', value: customAttributes.value?.geometry },
      {
        name: 'Weight',
        value: Number(customAttributes.value?.manufacturer_weight)
          ? String(Number(customAttributes.value?.manufacturer_weight).toFixed(2))
          : null,
        suffix: 'kg',
      },
      { name: 'Frame Shape', value: customAttributes.value?.frame_shape },
      { name: 'Material', value: customAttributes.value?.material },
      { name: 'Frame', value: customAttributes.value?.frame },
      { name: 'Fork', value: customAttributes.value?.fork },
    ];

    const hasKeyData = customAttributes.value?.model_year || customAttributes.value?.area_of_use
      || customAttributes.value?.geometry || customAttributes.value?.manufacturer_weight || customAttributes.value?.frame_shape
      || customAttributes.value?.material || customAttributes.value?.frame || customAttributes.value?.fork;

    const keyDataContent = `<div>${keyDataAttributes.map((attr: { name: string, value: string, suffix?: string }) => generateAttributeHtml(attr.name, attr.value, attr.suffix)).join('')}</div>`;

    const hasLandingGearData = customAttributes.value?.fork_travel || customAttributes.value?.damper
      || customAttributes.value?.manufacturer_suspension;

    const landingGearDataAttributes = [
      { name: 'Travel', value: customAttributes.value?.fork_travel },
      { name: 'Damper', value: customAttributes.value?.damper },
      { name: 'Suspension Manufacturer', value: customAttributes.value?.manufacturer_suspension },
    ];

    const landingGearDataContent = `<div>${landingGearDataAttributes.map((attr: { name: string, value: string }) => generateAttributeHtml(attr.name, attr.value)).join('')}</div>`;

    const hasDriveData = customAttributes.value?.drive_type || customAttributes.value?.transmission
      || customAttributes.value?.manufacturer_circuit || customAttributes.value?.groupset_type || customAttributes.value?.groupset
      || customAttributes.value?.rear_derailleur || customAttributes.value?.derailleur || customAttributes.value?.shifter
      || customAttributes.value?.crankset || customAttributes.value?.cassette;

    const driveDataAttributes = [
      { name: 'Drive Type', value: customAttributes.value?.drive_type },
      { name: 'Transmission', value: customAttributes.value?.transmission },
      { name: 'Circuit Manufacturer', value: customAttributes.value?.manufacturer_circuit },
      { name: 'Groupset Type', value: customAttributes.value?.groupset_type },
      { name: 'Groupset', value: customAttributes.value?.groupset },
      { name: 'Rear Derailleur', value: customAttributes.value?.rear_derailleur },
      { name: 'Front Derailleur', value: customAttributes.value?.derailleur },
      { name: 'Shifter', value: customAttributes.value?.shifter },
      { name: 'Crankset', value: customAttributes.value?.crankset },
      { name: 'Cassete', value: customAttributes.value?.cassette },
    ];

    const driveDataContent = `<div>${driveDataAttributes.map((attr: { name: string, value: string }) => generateAttributeHtml(attr.name, attr.value)).join('')}</div>`;

    const hasBrakesData = customAttributes.value?.brakes_type || customAttributes.value?.brakes
      || customAttributes.value?.manufacturer_brakes;

    const brakesDataAttributes = [
      { name: 'Brake System Type', value: customAttributes.value?.brakes_type },
      { name: 'Brake System', value: customAttributes.value?.brakes },
      { name: 'Brakes Manufacturer', value: customAttributes.value?.manufacturer_brakes },
    ];

    const brakesDataContent = `<div>${brakesDataAttributes.map((attr: { name: string, value: string }) => generateAttributeHtml(attr.name, attr.value)).join('')}</div>`;

    const hasWheelsData = customAttributes.value?.wheelset_material || customAttributes.value?.wheel_size
      || customAttributes.value?.wheels || customAttributes.value?.tires;

    const wheelsDataAttributes = [
      { name: 'Wheelset material', value: customAttributes.value?.wheelset_material },
      { name: 'Wheel Size', value: customAttributes.value?.wheel_size },
      { name: 'Wheels', value: customAttributes.value?.wheels },
      { name: 'Tires', value: customAttributes.value?.tires },
    ];

    const wheelsDataContent = `<div>${wheelsDataAttributes.map((attr: { name: string, value: string }) => generateAttributeHtml(attr.name, attr.value)).join('')}</div>`;

    const hasCockpitData = customAttributes.value?.integrated_cockpit || customAttributes.value?.handlebars
      || customAttributes.value?.stem || customAttributes.value?.saddle || customAttributes.value?.seatpost;

    const cockpitDataAttributes = [
      { name: 'Integrated Cockpit', value: customAttributes.value?.integrated_cockpit },
      { name: 'Handlebars', value: customAttributes.value?.handlebars },
      { name: 'Stem', value: customAttributes.value?.stem },
      { name: 'Saddle', value: customAttributes.value?.saddle },
      { name: 'Seatpost', value: customAttributes.value?.seatpost },
    ];

    const cockpitDataContent = `<div>${cockpitDataAttributes.map((attr: { name: string, value: string }) => generateAttributeHtml(attr.name, attr.value)).join('')}</div>`;

    const hasEBikeSystemData = !!customAttributes.value?.manufacturer_engine_name || !!customAttributes.value?.drive_unit
      || !!customAttributes.value?.battery || !!customAttributes.value?.range_extender_optional || !!customAttributes.value?.display
      || !!customAttributes.value?.display_individual || !!customAttributes.value?.remote || !!customAttributes.value?.remote_individual
      || !!customAttributes.value?.charger || !!customAttributes.value?.charger_individual || !!customAttributes.value?.drive_unit_individual;

    const eBikeSystemDataAttributes = [
      { name: 'Engine Manufacturer', value: customAttributes.value?.manufacturer_engine_name },
      { name: 'Drive Unit', value: customAttributes.value?.drive_unit },
      { name: 'Drive Unit Individual', value: customAttributes.value?.drive_unit_individual },
      { name: 'Battery Pack', value: String(Number(customAttributes.value?.battery).toFixed(0)) },
      { name: 'Range Extender Optional', value: customAttributes.value?.range_extender_optional ? i18n.t('Yes') : i18n.t('No') },
      { name: 'Display', value: customAttributes.value?.display },
      { name: 'Display Individual', value: customAttributes.value?.display_individual },
      { name: 'Remote', value: customAttributes.value?.remote },
      { name: 'Remote Individual', value: customAttributes.value?.remote_individual },
      { name: 'Charger', value: customAttributes.value?.charger },
      { name: 'Charger Individual', value: customAttributes.value?.charger_individual },
    ];

    const eBikeSystemDataContent = `<div>${eBikeSystemDataAttributes.map((attr: { name: string, value: string }) => generateAttributeHtml(attr.name, attr.value)).join('')}</div>`;

    const hasGeneralData = customAttributes.value?.body_height_kids || customAttributes.value?.luggage_carrier
      || customAttributes.value?.mudguards;

    const generalDataAttributes = [
      { name: 'Body size children\'s bikes', value: customAttributes.value?.body_height_kids },
      { name: 'Luggage Rack System', value: customAttributes.value?.luggage_carrier },
      { name: 'Mudguards', value: customAttributes.value?.mudguards },
    ];

    const generalDataContent = `<div>${generalDataAttributes.map((attr: { name: string, value: string }) => generateAttributeHtml(attr.name, attr.value)).join('')}</div>`;

    const hasGeometryDimensionsData = customAttributes.value?.geometry_dimensions;

    const magentoBaseUrl = $config?.magentoBaseUrl;
    let geometryDimensionsClean = customAttributes.value?.geometry_dimensions?.replace('{{media url=&quot;.renditions/', `${magentoBaseUrl}/media/`);
    geometryDimensionsClean = geometryDimensionsClean?.replace('&quot;}}', '');
    geometryDimensionsClean = geometryDimensionsClean?.replace('&display: block', 'display: test');

    const geometryDimensionsDataContent = `<div>
      ${customAttributes.value?.geometry_dimensions
        ? `
        <div class="py-2 max-md:overflow-auto product-accordeon__table">
          ${geometryDimensionsClean}
        </div>
      `
        : ''}
    </div>`;

    const manufacturerInfoContent = ref(null);

    const sections = computed(() => ([
      { hasData: hasKeyData, heading: 'Key Data', content: keyDataContent },
      { hasData: hasEBikeSystemData, heading: 'E-Bike System', content: eBikeSystemDataContent },
      { hasData: hasLandingGearData, heading: 'Landing Gear', content: landingGearDataContent },
      { hasData: hasDriveData, heading: 'Drive', content: driveDataContent },
      { hasData: hasBrakesData, heading: 'Brakes', content: brakesDataContent },
      { hasData: hasWheelsData, heading: 'Wheels', content: wheelsDataContent },
      { hasData: hasCockpitData, heading: 'Cockpit', content: cockpitDataContent },
      { hasData: hasGeometryDimensionsData, heading: 'Geometry & Dimensions', content: geometryDimensionsDataContent },
      { hasData: hasGeneralData, heading: 'General', content: generalDataContent },
      { hasData: true, heading: 'Manufacturer Info', content: manufacturerInfoContent.value },
    ]));

    const accordeonItems: ComputedRef<AccordeonItem[]> = computed(() => sections.value
      .filter((section) => section.hasData)
      .map((section, index) => ({
        _uid: String(index + 1),
        heading: i18n.t(section.heading),
        content: section.content,
      }) as AccordeonItem));

    const openStates: Ref<AccordeonItemState[]> = ref([]);
    accordeonItems.value.forEach((item: AccordeonItem, index: number) => openStates.value.push({ index, isOpen: false }));

    // handle manufacturer info
    // initially the values might not be set, as it's a separate graphQL query than the other product information
    watch(() => props.manufacturerInfo, (newManufacturerInfo) => {
      let attributes = [];
      if (newManufacturerInfo) {
        const manufacturerName = newManufacturerInfo ? `${newManufacturerInfo.name} -  ${newManufacturerInfo.companyName}` : null;
        const manufacturerNameData = newManufacturerInfo?.homepageUrl
          ? `<a href="${newManufacturerInfo?.homepageUrl}" target="_blank" class="!underline cursor-pointer">${manufacturerName}</a>`
          : manufacturerName;

        // product security link overrides manufacturer security link if provided
        const securityInfoLink = customAttributes.value?.link_to_security_info ?? newManufacturerInfo?.linkToSecurityInfo;
        const securityInfoData = securityInfoLink
          ? `<a href="${securityInfoLink}" target="_blank" class="!underline cursor-pointer truncate" >${i18n.t('Security Document Link')}</a>`
          : undefined;

        const email = newManufacturerInfo?.email ? `<a href="mailto:${newManufacturerInfo?.email}" class="!underline cursor-pointer truncate" >${newManufacturerInfo?.email}</a>` : undefined;
        const phoneNumber = newManufacturerInfo?.phoneNumber ? `<a href="tel:${newManufacturerInfo?.phoneNumber}" class="!underline cursor-pointer truncate" >${newManufacturerInfo?.phoneNumber}</a>` : undefined;

        attributes = [
          { name: 'Manufacturer Name', value: manufacturerNameData },
          { name: 'Manufacturer Address', value: newManufacturerInfo?.address },
          { name: 'Manufacturer Country', value: newManufacturerInfo?.country },
          { name: 'Manufacturer Email', value: email },
          { name: 'Manufacturer Phone Number', value: phoneNumber },
          { name: 'Manufacturer Security Document', value: securityInfoData },
        ];
      } else {
        const securityInfoLink = customAttributes.value?.link_to_security_info;
        const securityInfoData = securityInfoLink
          ? `<a href="${securityInfoLink}" target="_blank" class="!underline cursor-pointer truncate" >${i18n.t('Security Document Link')}</a>`
          : undefined;
        attributes = [
          { name: 'Manufacturer Security Document', value: securityInfoData },
        ];
      }

      // avoid double slash as storeUrl may contain a trailing slash or not
      const bikeClassificationUrl = `${storeUrl}/${Routes.BIKE_CLASSIFICATION}`.replace(/(?<!:)\/+/gm, '/');

      // NuxtLink is not working here as the html is passed as string to v-html directive
      const bikeClassification = customAttributes.value?.bike_classification
        ? `<a href="${bikeClassificationUrl}" target="_blank" class="!underline cursor-pointer truncate" >${customAttributes.value?.bike_classification}</a>`
        : undefined;

      // eans are only set for configurable products
      // fallback to product ean if eans are not set, empty or contain only null entries
      const eansWithData = props.eans?.filter((ean) => !!ean);
      const eans = eansWithData?.length > 0
        ? eansWithData.map((ean) => `<div>${ean}</div>`).join('')
        : customAttributes.value?.manufacturer_ean;
      attributes = [
        ...attributes,
        { name: 'Bike Classification', value: bikeClassification },
        { name: 'System Weight', value: customAttributes.value?.system_weight },
        { name: 'Manufacturer EAN', value: eans },
      ];

      // in case there are no attributes set, populate with a placeholder
      const hasAttributes = attributes.findIndex((attr: { value: string }) => !!attr.value) !== -1;
      manufacturerInfoContent.value = hasAttributes
        ? `<div>${attributes.map((attr: { name: string, value: string }) => generateAttributeHtml(attr.name, attr.value)).join('')}</div>`
        : `<div>${generateAttributeHtml('Manufacturer Info Empty', i18n.t('Manufacturer Info Empty Text').toString())}</div>`;
    }, { immediate: true });

    // Open first item
    if (openStates.value.length > 0) openStates.value[0].isOpen = true;

    return {
      openStates,
      accordeonItems,
    };
  },
  onMounted() {},
  methods: {
    openAccordeon(index: number): void {
      const { isOpen } = this.openStates.find((state: any) => state.index === index);
      this.openStates.find((state: any) => state.index === index).isOpen = !isOpen;
    },
    getIconByState(isOpen: boolean) {
      return isOpen ? EnumAccordeonItemIcon.MINUS : EnumAccordeonItemIcon.PLUS;
    },
    isOpen(index: number): boolean {
      return this.openStates[index].isOpen;
    },
  },
});
